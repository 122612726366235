import consumer from "./consumer"

document.addEventListener('DOMContentLoaded', () => {
  const liveUpdatesContainer = document.getElementById('live-updates');
  if(liveUpdatesContainer){
    const resourceType = liveUpdatesContainer.dataset.resourceType;
    const resourceId = liveUpdatesContainer.dataset.resourceId;
  
    consumer.subscriptions.create({ channel: "LiveUpdatesChannel", resource_type: resourceType, resource_id: resourceId }, {
      received(data) {
        const updateElement = document.createElement('div');
        updateElement.classList.add('live-update', 'shadow-sm', 'info-box', 'mt-3', 'mb-3');
  
        updateElement.innerHTML = data.content;
        if (data.image_url) {
          const img = document.createElement('img');
          img.src = data.image_url;
          img.classList.add('update-image', 'rounded-lg');
          updateElement.appendChild(img);
        }
  
        // Prepend the new update element to the container
        liveUpdatesContainer.insertBefore(updateElement, liveUpdatesContainer.firstChild);
      }
    });
  }
});