$(document).on('turbolinks:load', function () {
  setTimeout(function () {
    $('.alert').not('.no-hide').fadeTo(1000, 0).slideUp(1000, function () {
      $(this).remove();
    });
  }, 3000)

  $('[data-toggle="popover"]').popover()

  $('.toggle-menu-button').on('click', function () {
    $('.animated-menu').toggleClass('open');
  })

  $('.language').on('click', function () {
    $('.overlay.language-overlay').toggleClass('open');
  })

  $('#lang-close').on('click', function () {
    $('.overlay.language-overlay').hide();
  })

  $('input.required').each(function () {
    $(this).attr('placeholder', $(this).attr('placeholder') + '*')
  })

  $("tr[data-link]").click(function () {
    window.location = $(this).data("link")
  })

  $('#close').on('click',function(event) {
    $('.popup-modal').hide();
  })

  if ($('.expand-box').length) {
    const url = document.location.toString();

    if (url.match('#')) {
      const hash = url.split('#')[1];

      $('.expand-box.collapse').removeClass('show');
      $('#' + hash).addClass('show');
    }
  }

  if ($('.menu-expand').length) {
    const url = document.location.toString();
    const hash = url.split('#')[1]; // Potentially undefined
  
    // Check if hash exists before using it
    if (hash) {
      $('.' + hash).show();
    }
  }

  $('.custom-file-input').change(function () {
    $(this).next().after().text($(this).val().split('\\').slice(-1)[0])
  })

  if ($('*[data-count-chars="true"]').length > 0) {
    const review_text = $('*[data-count-chars="true"]')
    const counter = $("#char-counter-display")
    const max_length = counter.data("maximum-length")

    review_text.keyup(function () {
      counter.text(max_length - $(this).val().length);
    })
  }
  $(document).ready(function(){
    $('.profileImage').each(function(i, entry) {
      var intials = $(entry).data('name');
      var profileImage = $(entry).text(intials);
    });

    $('.newprofileImage').each(function(i, entry) {
      var intials = $(entry).data('name');
      var profileImage = $(entry).text(intials);
    });

    $('.carousel-custom-logo').each(function(i, entry) {
      var intials = $(entry).data('name');
      var profileImage = $(entry).text(intials);
    });
    
  });
})
