$(document).on('turbolinks:load', function () {
  $('.toggle-menu-button').click(function (e) {
    e.preventDefault()
    $('.overlay.navigation-overlay').fadeToggle(200);
  })

  $('.overlay.navigation-overlay').on('click', function () {
    $('.overlay.navigation-overlay').fadeToggle(200);
  })

  $('.team-navigation-button').click(function (e) {
    e.preventDefault()
    $('.overlay.team-overlay').fadeToggle(200);
  })

  $('.overlay.team-overlay').on('click', function () {
    $('.overlay.team-overlay').fadeToggle(200);
  })

  $('.language').on('click', function () {
    $('.overlay.language-overlay').fadeToggle(200);
  })

  const breadcrumb = document.querySelector('.breadcrumb');

  if (breadcrumb){
    // Add an event listener to the menu toggle button to hide/show breadcrumbs
    document.querySelector('#menu-toggle-button').addEventListener('click', () => {
      breadcrumb.style.display = 'none'; // Hide the breadcrumbs
    });

    if(document.querySelector('.dropdown-toggle')){
      document.querySelector('.dropdown-toggle').addEventListener('click', () => {
        breadcrumb.style.display = 'none'; // Hide the breadcrumbs
      });
    }
    
    document.querySelector('#menu-close-button').addEventListener('click', () => {
      breadcrumb.style.display = ''; // Show the breadcrumbs
    });

    document.querySelector('.close-btn').addEventListener('click', () => {
      breadcrumb.style.display = ''; // Show the breadcrumbs
    });
    
  }

  const breadcrumbs = document.querySelectorAll('.breadcrumb li');
  if(breadcrumbs){
    // Check the number of breadcrumbs
    const numBreadcrumbs = breadcrumbs.length;

    if (numBreadcrumbs > 3) { // Change 4 to 3
      // Apply the CSS to the <a> tags inside the middle breadcrumbs
      for (let i = 1; i < numBreadcrumbs - 2; i++) {
        breadcrumbs[i].style.display = 'block';
        breadcrumbs[i].style.width = '1px';


        const anchor = breadcrumbs[i].querySelector('a');
        anchor.style.display = 'none';

        // Create an invisible link that overlays the :after element
        const invisibleLink = document.createElement('a');
        invisibleLink.href = anchor.getAttribute('href');
        invisibleLink.style.position = 'absolute';
        invisibleLink.style.right = '-1.3rem';
        invisibleLink.style.top = '0';
        invisibleLink.style.width = '3rem';
        invisibleLink.style.height = '3rem';
        invisibleLink.style.borderRadius = '50%';
        invisibleLink.style.zIndex = '3';
        breadcrumbs[i].appendChild(invisibleLink);

        if (numBreadcrumbs > 2) {
          const secondToLastBreadcrumb = breadcrumbs[numBreadcrumbs - 2];
          const secondToLastInvisibleLink = secondToLastBreadcrumb.querySelector('a');
          const spanForTextCropping = document.createElement('span');
          spanForTextCropping.innerText = secondToLastInvisibleLink.innerText;
          secondToLastInvisibleLink.innerHTML = '';
          secondToLastInvisibleLink.appendChild(spanForTextCropping);
          spanForTextCropping.style.display = 'inline-block';
          spanForTextCropping.style.width = '3rem';
          spanForTextCropping.style.overflow = 'hidden';
          spanForTextCropping.style.whiteSpace = 'nowrap';
          spanForTextCropping.style.textOverflow = 'ellipsis';
        }


        // Calculate the number of breadcrumbs to remove
        const breadcrumbsToRemove = numBreadcrumbs - 4;

        // Hide the breadcrumbs from the second to the last - 2
        for (let i = 1; i <= breadcrumbsToRemove; i++) {
          breadcrumbs[i].style.display = 'none';
        }
        // Apply blue color to the last breadcrumb that remains visible
        if (numBreadcrumbs - breadcrumbsToRemove > 3) {
          breadcrumbs[breadcrumbsToRemove+1].classList.add('blue-breadcrumb');
        }
        const lastBreadcrumb = breadcrumbs[breadcrumbs.length - 1];
        lastBreadcrumb.textContent = lastBreadcrumb.textContent.substring(0,10)
      }
    }
    if(breadcrumbs.length == 3){
      // shortens the last breadcrumb string
      const lastBreadcrumb = breadcrumbs[breadcrumbs.length - 1];
      if(lastBreadcrumb.textContent.length > 10){
        lastBreadcrumb.textContent = lastBreadcrumb.textContent.substring(0,10) + '..'
      }

        const secondBreadcrumb = breadcrumbs[breadcrumbs.length - 2];
        const anchorTag = secondBreadcrumb.querySelector('a'); // Select the <a> tag within the secondBreadcrumb
        if (anchorTag) {
          anchorTag.textContent = anchorTag.textContent.substring(0, 5) + '...'; // Shorten the text of the <a> tag
        }
    }

  }

});
