import Swal from 'sweetalert2'

$(document).on('turbolinks:load', function () {


  function openSingleSweetAlert(id, event_result_id, amount, sportpengen_amount, admin_mail, backer_name, mode) {
    console.log( "sponsorship_id >>>>> " + id);
    console.log( "event_result_id >>>>>" + event_result_id)
    console.log("sportpengen_amount >>>>>>" + sportpengen_amount);
    console.log("amount >>>>>>" +amount);
    console.log("admin mail >>>>>>" + admin_mail);
    console.log('Backer_name >>>>>>' + backer_name)
    // Open SweetAlert2 modal with custom HTML content
    Swal.fire({
        title: `${I18n.t('result_refund.backing_popup.title')}`,
        html:
            `<form id="swalForm" style="padding: 1rem;">
                <div class="dropdown_div">
                    <label for="swalDropdown">${I18n.t('result_refund.backing_popup.refund_backer', {user_name: backer_name, amount: amount})}</label>
                    <select class="form-control" id="swalDropdown" required>
                    <option style="color: grey;" value="" disabled selected>${I18n.t('result_refund.result_popup.refund_reason')}</option>
                    <option value="duplicate">${I18n.t('result_refund.result_popup.duplicate')}</option>
                    <option value="fraudulent">${I18n.t('result_refund.result_popup.fraudulent')}</option>
                    <option value="my_mistake">${I18n.t('result_refund.result_popup.my_mistake')}</option>
                    <option value="request_by_backer">${I18n.t('result_refund.result_popup.request_by_backer')}</option>
                    <option value="other">${I18n.t('result_refund.result_popup.other')}</option>
                    <option value="sportpengen_bug">${I18n.t('result_refund.result_popup.sportpengen_bug')}</option>
                        <!-- Add more options as needed -->
                    </select>
                </div>
                <div style="margin-top: 10px;">
                    <label for="swalTextInput"></label>
                    <textarea class="form-control" id="swalTextInput" rows="4" required placeholder="${I18n.t('result_refund.result_popup.message_to_backers_placeholder')}"></textarea>
                </div>
                <div id="general_context" style="display: block;">
                    <p class="mt-4">${I18n.t('result_refund.backing_popup.normal_context1', {user_name: backer_name, amount: amount})}</p>
                    <p>${I18n.t('result_refund.backing_popup.normal_context2')}</p>
                    <p class="mt-4" style="font-weight: 500; margin-bottom: -20px;">${I18n.t('result_refund.backing_popup.normal_context3')}</p>
                </div>

                <div id="bug_context" style="display: none;">
                    <p class="mt-4">${I18n.t('result_refund.result_sportpengen_popup.sportpengen_context1')}</p>
                    <label for="swalTextInputMessage"></label>
                    <textarea class="form-control" id="swalTextInputMessage" rows="4" required placeholder="${I18n.t('result_refund.result_sportpengen_popup.message_to_backers')}"></textarea>
                    <p class="mt-4">${I18n.t('result_refund.backing_sportpengen_popup.sportpengen_context1')}</p>
                    <p>${I18n.t('result_refund.backing_sportpengen_popup.sportpengen_context2', {admin_mail: admin_mail})}</p>
                    <p>${I18n.t('result_refund.backing_sportpengen_popup.sportpengen_context3', {user_name: backer_name, amount: sportpengen_amount})}</p>
                    <p class="mt-4" style="font-weight: 500; margin-bottom: -20px;">${I18n.t('result_refund.backing_sportpengen_popup.sportpengen_context4')}</p>
                </div>
                
                <div id="imageSelector" style="margin-top: 10px; display: none;">
                    <label for="swalImageInput">Select an image:</label>
                    <input type="file" class="form-control" id="swalImageInput">
                </div>
            </form>`,
        showCancelButton: true,
        confirmButtonColor: '#4d7de1',
        cancelButtonColor: '#DB4437',
        confirmButtonText: `${I18n.t('result_refund.backing_popup.button')}`,
        cancelButtonText: `${I18n.t('sponsorships.cancel')}`,
        showLoaderOnConfirm: true,
        onOpen: () => {
          // Hide the jumbotron when the Swal popup opens
          console.log('opened swal');
          if (document.querySelector('.target-backerlist-class')) {
            let jumbotrons = document.querySelectorAll('.jumbotron');
            jumbotrons.forEach(jumbotron => {
              jumbotron.style.display = 'none';
            });
          }
        },
        onClose: () => {
          // Show the jumbotron when the Swal popup closes
          console.log('swal closed');
          if (document.querySelector('.target-backerlist-class')) {
            let jumbotrons = document.querySelectorAll('.jumbotron');
            jumbotrons.forEach(jumbotron => {
              jumbotron.style.display = 'block';
            });
          }
        },
        onBeforeOpen: () => {
            // Add change event listener to the dropdown
            document.getElementById('swalDropdown').addEventListener('change', function () {
                // Get the selected option value
                const selectedOption = this.value;

                // Toggle the visibility of the image selector based on the selected option
                const imageSelector = document.getElementById('imageSelector');
                const general_context = document.getElementById('general_context');
                const bug_context = document.getElementById('bug_context');
                var swalTextInput = document.getElementById('swalTextInput');
                var confirm_button = document.querySelector('.swal2-confirm');

                if (selectedOption === 'sportpengen_bug') {
                    // imageSelector.style.display = 'block';
                    general_context.style.display = 'none';
                    bug_context.style.display = 'block';
                    swalTextInput.placeholder = `${I18n.t('result_refund.result_sportpengen_popup.message_to_sportpengen')}`;
                    confirm_button.innerHTML = `${I18n.t('result_refund.backing_sportpengen_popup.button')}`;
                } else {
                    // imageSelector.style.display = 'none';
                    general_context.style.display = 'block'
                    bug_context.style.display = 'none';
                    swalTextInput.placeholder = `${I18n.t('result_refund.result_popup.message_to_backers_placeholder')}`;
                    confirm_button.innerHTML = `${I18n.t('result_refund.backing_popup.button')}`;
                }
            });
        },
        preConfirm: () => {
            // Get form values from the SweetAlert2 modal
            const swalDropdownValue = document.getElementById('swalDropdown').value;
            const swalTextInputValue = document.getElementById('swalTextInput').value;
            const swalImageInputValue = document.getElementById('swalImageInput') ? document.getElementById('swalImageInput').value : null;
            const swalTextInputMessageValue = document.getElementById('swalTextInputMessage').value;
            // Check if both dropdown and text input are filled
            const isSwalFormFilled = swalDropdownValue.trim() !== '' && swalTextInputValue.trim() !== '';

            if (!isSwalFormFilled) {
                Swal.showValidationMessage(`${I18n.t('result_refund.result_popup.error_message')}`);
            }

            return { isSwalFormFilled, swalDropdownValue, swalTextInputValue, swalImageInputValue, swalTextInputMessageValue };
        },
        allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
        // Redirect to a new URL if the user clicked OK and the form is valid
        if (result.value.isSwalFormFilled) {
            // Use encodeURIComponent to handle special characters in the URL parameters
            const encodedDropdownValue = encodeURIComponent(result.value.swalDropdownValue);
            const encodedTextInputValue = encodeURIComponent(result.value.swalTextInputValue);
            const encodedTextInputMessageValue = encodeURIComponent(result.value.swalTextInputMessageValue);
            const encodedImageInputValue = result.value.swalImageInputValue ? encodeURIComponent(result.value.swalImageInputValue) : '';

            // Replace the following line with your actual redirect logic
            // window.location.href = `/`;
            window.location.href = `/refund_submit?dropdown=${encodedDropdownValue}&textInput=${encodedTextInputValue}&imageInput=${encodedImageInputValue}&id=${event_result_id}&sponsorship_id=${id}&message=${encodedTextInputMessageValue}&mode=${mode}`;
        }
    });
}



  // Get all elements with class .CanelResult
  const refundButtons = document.querySelectorAll('.RefundButton');

  // Loop through each button and attach the event listener
  refundButtons.forEach((button) => {
    const dataValue = button.getAttribute('data-event-result-id');
    const amount = button.getAttribute('data-amount');
    const sportpengen_amount = button.getAttribute('data-sportpengen-amount');
    const admin_mail = button.getAttribute('data-team_admin_mail');
    const backer_name = button.getAttribute('data-backer-name');
    const mode = button.getAttribute('data-mode');
    console.log("sportpengen_amount >>>>>>" + sportpengen_amount);
    console.log("amount >>>>>>" +amount);
    console.log("admin mail >>>>>>" + admin_mail);
    console.log('Backer_name >>>>>>' + backer_name)
      button.addEventListener('click', () => openSingleSweetAlert(button.id, dataValue, amount, sportpengen_amount, admin_mail, backer_name, mode));
  });


  $('#confirm-result-button').on('click', function (event) {
    event.preventDefault();

      // Fetch the text from the <p> tag with id "type-of-event"
      var eventType = $("#type-of-event").text().trim();

      // Check for empty text boxes based on the event type and collect error messages
      var errorMessages = checkForEmptyTextBoxes(eventType);
      if (errorMessages.length > 0) {
        var form = document.getElementById('new_event_result');
        form.reportValidity();
      }else{
        Swal.fire({
          title: $(this).data('confirm-confirm'),
          confirmButtonText: $(this).data('confirm-sign'),
          confirmButtonColor: '#4d7de1',
          cancelButtonColor: '#DB4437',
          showCancelButton: true,
          cancelButtonText: $(this).data('confirm-cancel'),
          html: $(this).data('confirm-heading') + getScore($(this).data('team-name'), eventType) + get_confirm_text($(this).data('confirm-text')),
          icon: 'warning',
          customClass: {
            popup: 'custom-class-popup'
          }
        }).then(function (result) {
          if (result.value) {
            // $('.event-result-form').submit()

            // Submit the form using AJAX
            var formData = new FormData($('.event-result-form')[0]);
            $.ajax({
                url: $('.event-result-form').attr('action'),
                type: 'POST',
                data: formData,
                contentType: false,
                processData: false,
                success: function (response) {
                  // Handle success response
                  console.log(response);

                  // Get the URL
                  var url = response;

                  // Extract the event_result_id from the URL
                  var eventResultId = url.match(/event_result_id=(\d+)/)[1];

                  // Make an AJAX request to fetch @event_result.revenue
                  $.ajax({
                      url: '/api/event_results/' + eventResultId + '/revenue',
                      type: 'GET',
                      success: function(response) {
                          var revenue = response.revenue;
                          // Do something with the revenue
                          console.log('Revenue:', revenue);
                          if(revenue > 0){
                            Swal.fire({
                              showConfirmButton: false, // Set to true to show the close
                              allowOutsideClick: false, // Prevent closing on click outside
                              html: get_loading_transaction_context()
                            });
                          }else{
                            Swal.fire({
                              showConfirmButton: false, // Set to true to show the close
                              allowOutsideClick: false, // Prevent closing on click outside
                              html: get_not_transaction()
                            });
                          }
                      },
                      error: function(xhr, status, error) {
                          console.error('Error:', error);
                      }
                  });
                },
                error: function (xhr, status, error) {
                    console.error(error);
                    // Handle error
                }
            });



            // Swal.fire({
            //   showConfirmButton: false, // Set to true to show the close
            //   allowOutsideClick: false, // Prevent closing on click outside
            //   html: get_loading_transaction_context()
            // });
          
            function get_loading_transaction_context(){
              return '<i style="font-size: 2rem;" class="fa-solid fa-wallet"></i>' +
                     '<h4>'+ I18n.t('common.activating_transactions')  +'</h4>' +
                     '<div style="margin-top: 1.5rem; margin-bottom: 1.5rem; width: 2.5rem; height: 2.5rem;" class="loading-spinner-big"></div>'
            }

            function get_not_transaction(){
              return '<h5>'+ I18n.t('events.finalizing_result')  +'</h5>' +
                     '<h5>'+ I18n.t('events.does_not_activate_transacions')  +'</h5>' +
                     '<div style="margin-top: 1.5rem; margin-bottom: 1.5rem; width: 2.5rem; height: 2.5rem;" class="loading-spinner-big"></div>'
            }
          }
        });
  
        var swal_popup = document.querySelector('.swal2-popup');
        if (swal_popup) {
          swal_popup.classList.add('info-box');
        }
      }
  })

  $('.expand-box-child-trigger').click(function (e) {
    $('' + $(this).data('target')).collapse('toggle')
    e.stopPropagation()
  })


  function checkForEmptyTextBoxes(eventType) {
    var errorMessages = [];
  
    function validateCommonFields() {
      addErrorMessage('#event_result_result_data_opponent_team_name', 'Please input opponent team name');
      addErrorMessage('#event_result_result_data_home_team_score', 'Please input home team score!');
      addErrorMessage('#event_result_result_data_away_team_score', 'Please input away team score!');
    }
  
    function validateAudienceFields() {
      validateCommonFields();
      addErrorMessage('#event_result_result_data_audience_count', 'Please input the spectators count!');
    }
  
    function validateCustomFields() {
      addErrorMessage('#event_result_result_data_custom_count', 'Please input the custom unit count!');
    }
  
    function addErrorMessage(field, message) {
      if ($(field).val().trim() === '') {
        errorMessages.push(message);
      }
    }
  
    switch (eventType) {
      case "goal":
      case "point":
      case "match":
      case "win":
        validateCommonFields();
        break;
  
      case "audience":
        validateAudienceFields();
        break;
  
      case "custom":
        validateCustomFields();
        break;
    }
  
    return errorMessages;
  }

  // -----------------------

  function getScore(team_name, eventType) {

    var home_score = document.getElementById('event_result_result_data_home_team_score').value;
    var away_score = document.getElementById('event_result_result_data_away_team_score').value;
    const opponent_name = document.getElementById('event_result_result_data_opponent_team_name').value;
    const audience_count = document.getElementById('event_result_result_data_audience_count').value;
    const custom_count = document.getElementById('event_result_result_data_custom_count').value;
    const result_create_date = document.getElementById('event_result_date').value;
    const imagePicker = document.getElementById('file-input-second');
    const opponentImagePicker = document.getElementById('file-input');
    
    const message = document.getElementById('event_result_description').value;

    var image_present = document.getElementById('image-present').innerHTML;
    var team_image_url = document.getElementById('image-url').innerHTML;

    if (image_present === "false") {
      const association_image_present = document.getElementById('association-image-present').innerHTML.trim();
      const association_image_url = document.getElementById('association-image-url').innerHTML.trim();
      
      if (association_image_present === "true") {
        image_present = "true";
        team_image_url = association_image_url;
      }
    }
  
    var isAwayGame = document.querySelector('input[name="event_result[result_data[away_game]]"]:checked').value == 1;
    const score = isAwayGame ? away_score + ' - ' + home_score : home_score + ' - ' + away_score;
    const biggerIcon = '<div style="display: flex; justify-content: space-between;"><i class="fa-solid fa-circle-up" style="color: #07a211;"></i><i class="fa-solid fa-circle-up fa-flip-vertical" style="color: #a20707;"></i></div>';
    const smallerIcon = '<div style="display: flex; justify-content: space-between;"><i class="fa-solid fa-circle-up fa-flip-vertical" style="color: #a20707;"></i><i class="fa-solid fa-circle-up" style="color: #07a211;"></i></div>';
    const equalIcon = '<i class="fa-solid fa-circle-minus fa-rotate-by" style="color: #3b3b3b; --fa-rotate-angle: 135deg;"></i>';
    
    home_score = parseInt(home_score, 10);
    away_score = parseInt(away_score, 10);
    
    var awaygameicons = away_score > home_score ? biggerIcon : smallerIcon;
    var homegameicons = home_score > away_score ? biggerIcon : smallerIcon;

    const icons = away_score === home_score ? equalIcon
      : isAwayGame ? awaygameicons : homegameicons;

    let resultHtml = ''

    resultHtml += '<br><br>'

    if (result_create_date) {
      resultHtml += '<div class="custom-result-date"><p>' + result_create_date + '</p></div>';
    }

    if(['training', 'one_time', 'monthly'].includes(eventType)){
      let teamNameHtml = '<b style="flex: 1; overflow: hidden; text-overflow: ellipsis; display: flex; flex-direction: column; align-items: center;">';
      
      if (image_present == 'true') {
        teamNameHtml += '<img src="' + team_image_url + '" class="team-logo ml-0 img-fluid d-block" />';
      }

      teamNameHtml += team_name + '</b>';

      resultHtml += '<div class="custom-result-score-box" style="display: flex; align-items: center; padding: 10px 10px;">' +
                    teamNameHtml +
                    '</div>' ;
    }

    if (/\d/.test(score) && !(['training', 'one_time', 'monthly'].includes(eventType))) {
      if (/\d/.test(score) && !(['training', 'one_time', 'monthly'].includes(eventType))) {
        let teamNameHtml = '<b id="team-name" style="flex: 1; text-overflow: ellipsis; display: flex; flex-direction: column; align-items: center;">';
      
        if (image_present == 'true' && !isAwayGame) {
          teamNameHtml += '<img src="' + team_image_url + '" class="result_image team-logo ml-0 img-fluid d-block" />';
        }

        if (opponentImagePicker.files.length > 0 && isAwayGame) {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
    
          const img = new Image();
          img.onload = function () {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
    
            // Now you can use the canvas.toDataURL() to get a data URL
            const imageUrl = canvas.toDataURL('image/png');
    
            // Use the imageUrl as needed (e.g., setting it as the src attribute of an img element)
            const resultContainer = document.getElementById('team-name')
            const resultContainerName = resultContainer.innerHTML;
            const resultHtml = '<img src="' + imageUrl + '" class="result_image team-logo ml-0 img-fluid d-block" />';
            resultContainer.innerHTML = resultHtml;
            resultContainer.innerHTML += resultContainerName;
            console.log(resultContainer.innerHTML);
          };
          img.src = URL.createObjectURL(opponentImagePicker.files[0]);
        }
      
        teamNameHtml += (isAwayGame ? opponent_name : team_name) + '</b>';
      
        let opponentNameHtml = '<b id="opponent-name" style="flex: 1; text-overflow: ellipsis; display: flex; flex-direction: column; align-items: center;">';
      
        if (image_present == 'true' && isAwayGame) {
          opponentNameHtml += '<img src="' + team_image_url + '" class="result_image team-logo ml-0 img-fluid d-block" />';
        }

        if (opponentImagePicker.files.length > 0 && !isAwayGame) {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
    
          const img = new Image();
          img.onload = function () {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
    
            // Now you can use the canvas.toDataURL() to get a data URL
            const imageUrl = canvas.toDataURL('image/png');
    
            // Use the imageUrl as needed (e.g., setting it as the src attribute of an img element)
            const resultContainer = document.getElementById('opponent-name')
            const resultContainerName = resultContainer.innerHTML;
            const resultHtml = '<img src="' + imageUrl + '" class="result_image team-logo ml-0 img-fluid d-block" />';
            resultContainer.innerHTML = resultHtml;
            resultContainer.innerHTML += resultContainerName;
            console.log(resultContainer.innerHTML);
          };
          img.src = URL.createObjectURL(opponentImagePicker.files[0]);
        }
      
        opponentNameHtml += (isAwayGame ? team_name : opponent_name) + '</b>';
      
        resultHtml += '<div class="custom-result-score-box" style="display: flex; align-items: center; padding: 10px 10px;">' +
          teamNameHtml +
          '<div>'+
            '<h1 style="flex: 1; white-space: nowrap;">' + score + '</h1>'+
            '<div>' + icons + '</div>' +
          '</div>' +
          opponentNameHtml +
          '</div>';
      }
    }

    if (audience_count && !(['training', 'one_time', 'monthly'].includes(eventType))) {
      resultHtml += '<div class="custom-text-audience-count"><p>' + I18n.t('common.spectators') +': ' + audience_count + '</p></div>';
    }

    if (custom_count && eventType == "custom" && !(['training', 'one_time', 'monthly'].includes(eventType))) {
      const custom_variable = document.getElementById('event_result_result_data_custom_count').placeholder.slice(0, -1);

      resultHtml += '<div class="custom-text-audience-count"><p>' + custom_variable + ': ' + custom_count + '</p></div>';
    }
    
    if (imagePicker.files.length > 0) {
      resultHtml += '<div class="custom-image-container" id="custom-image-container"></div>';
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      const img = new Image();
      img.onload = function () {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);

        // Now you can use the canvas.toDataURL() to get a data URL
        const imageUrl = canvas.toDataURL('image/png');

        // Use the imageUrl as needed (e.g., setting it as the src attribute of an img element)
        const resultContainer = document.getElementById('custom-image-container')
        const resultHtml = '<img style="width: 100%;" src="' + imageUrl + '" alt="Selected Image">';
        resultContainer.innerHTML = resultHtml;
        console.log(resultContainer.innerHTML);
      };
      img.src = URL.createObjectURL(imagePicker.files[0]);
    }

    if (message) {
      resultHtml += '<div class="custom-text-audience-count" style="font-size: large; text-align: left; padding-top: 10px;"><p>' + message + '</p></div>';
    }

    resultHtml += '<br><br>';

    return resultHtml;

  }
  
  function get_confirm_text(text){
    return '<p style="text-align: left; padding: 0px 10px;">' + text + '</p>';
  }
})
