import Swal from 'sweetalert2'
$(document).on('turbolinks:load', function () {

  $('.recruiter-qr-btn').on('click', function (e) {
    let url = e.target.id;
    if(url){
      Swal.fire({
      title: document.getElementById('event_loading_message_text').innerHTML,
      imageUrl: url,
      imageWidth: 400,
      imageHeight: 400,
      imageAlt: 'Custom image',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: document.getElementById('event_download_text').innerHTML,
      cancelButtonText: document.getElementById('event_close_text').innerHTML
    }).then((result) => {
      if (result.value) {
        var a = $("<a>").attr("href", url).attr("download", "Sportpengen_QRcode.png").appendTo("body");
        a[0].click();
        a.remove();
        Swal.fire(
          document.getElementById('event_downloading_text').innerHTML,
          document.getElementById('event_downloading_message_text').innerHTML
        )
        var swal_popup = document.querySelector('.swal2-popup');
        if (swal_popup) {
          swal_popup.classList.add('info-box');
        }
      }
    })
    var swal_popup = document.querySelector('.swal2-popup');
    if (swal_popup) {
      swal_popup.classList.add('info-box');
    }
    document.getElementsByClassName('swal2-confirm swal2-styled')[0].style = 'border-radius:2rem; background: #4d7de1;'
    document.getElementsByClassName('swal2-cancel swal2-styled')[0].style = 'border-radius:2rem; background: #dc3545;'
    var img = document.getElementsByClassName('swal2-image')[0]
    img.onload = function() {
      $('#swal2-title').fadeOut('fast');
    }
    document.getElementsByClassName('swal2-image')[0].style.height = "100%"
    document.getElementsByClassName('swal2-image')[0].style.width = "100%"
  }
  });


  $('.rec-delete-button').on('click', function (e) {

    let team_id = document.getElementById('team_id').innerHTML
    let event_id = document.getElementById('event_id').innerHTML
      Swal.fire({
        title: document.getElementById('sure').innerHTML,
        text: document.getElementById('sure_message').innerHTML,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: document.getElementById('delete').innerHTML,
        cancelButtonText: document.getElementById('cancel').innerHTML
    }).then((result) => {
      if (result.value) {
        $.ajax({
          url: '/' + I18n.currentLocale() + '/teams/'+ team_id + '/recruiters/?event_id=' + event_id + '&id='+ this.id.split('delete')[1],
          type: 'DELETE',
          dataType: 'json',
          error: function (e) {
            location.reload();            
          },
          success: function (e) {
            location.reload();            
          }
        })
      }
    })
    var swal_popup = document.querySelector('.swal2-popup');
    if (swal_popup) {
      swal_popup.classList.add('info-box');
    }
    document.getElementsByClassName('swal2-confirm swal2-styled')[0].style = 'border-radius:2rem; background: #4d7de1;'
    document.getElementsByClassName('swal2-cancel swal2-styled')[0].style = 'border-radius:2rem; background: #dc3545;'
  });
  




  $('.edit-button').on('click', function (e) {
    let team_id = document.getElementById('team_id').innerHTML
    let event_id = document.getElementById('event_id').innerHTML
    Swal.fire({
      title: document.getElementById('edit_user').innerHTML,
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: document.getElementById('update').innerHTML,
      cancelButtonText: document.getElementById('cancel').innerHTML,
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.value) {
        $.ajax({
          url: '/' + I18n.currentLocale() + '/teams/'+ team_id + '/recruiters/?event_id=' + event_id + '&id='+ this.id.split('edit')[1] + '&name=' + result.value,
          type: 'PUT',
          dataType: 'json',
          error: function (e) {
            location.reload();            
          },
          success: function (e) {
            location.reload();            
          }
        })
      }

    })

    var swal_popup = document.querySelector('.swal2-popup');
    if (swal_popup) {
      swal_popup.classList.add('info-box');
    }

    $('.swal2-input').val(document.getElementById(this.id + 'name').innerHTML
    );
    document.getElementsByClassName('swal2-confirm swal2-styled')[0].style = 'border-radius:2rem; background: #4d7de1;'
    document.getElementsByClassName('swal2-cancel swal2-styled')[0].style = 'border-radius:2rem; background: #dc3545;'
  });


  $('.association-editor-delete-button').on('click', function (e) {
      var association_id = $(this).data('association_id');
      let confirm_heading = document.getElementById('administrator_confirm_heading').innerHTML
      let confirm_text = document.getElementById('administrator_confirm_text').innerHTML
      Swal.fire({
        title: $(this).data('confirm_confirm'),
        confirmButtonText: $(this).data('confirm_sign'),
        confirmButtonColor: '#4d7de1',
        cancelButtonColor: '#DB4437',
        showCancelButton: true,
        cancelButtonText: $(this).data('confirm_cancel'),
        html: confirm_heading + '<br />' + confirm_text,
        icon: 'warning',
    }).then((result) => {
      if (result.value) {
        $.ajax({
          url: '/' + I18n.currentLocale() + '/associations/'+ association_id + '/delete_editor/?editor_id=' +  $(this).data('editor_id'),
          type: 'GET',
          dataType: 'json',
          error: function (e) {
            window.location.href = '/' + I18n.currentLocale() + '/associations/'+ association_id + '/administrators';
          },
          success: function (e) {
            window.location.href = '/' + I18n.currentLocale() + '/associations/'+ association_id + '/administrators';
          }
        })
      }
    })
    var swal_popup = document.querySelector('.swal2-popup');
    if (swal_popup) {
      swal_popup.classList.add('info-box');
    }
  });

  $('.team-editor-delete-button').on('click', function (e) {
    var association_id = $(this).data('association_id');
    var team_id = $(this).data('team_id');
    let confirm_heading = document.getElementById('administrator_confirm_heading').innerHTML
    let confirm_text = document.getElementById('administrator_confirm_text').innerHTML
    Swal.fire({
      title: $(this).data('confirm_confirm'),
      confirmButtonText: $(this).data('confirm_sign'),
      confirmButtonColor: '#4d7de1',
      cancelButtonColor: '#DB4437',
      showCancelButton: true,
      cancelButtonText: $(this).data('confirm_cancel'),
      html: confirm_heading + '<br />' + confirm_text,
      icon: 'warning',
  }).then((result) => {
    if (result.value) {
      $.ajax({
        url: '/' + I18n.currentLocale() + '/associations/'+ association_id + '/team_users/' + team_id + '/delete_editor/?editor_id=' +  $(this).data('editor_id'),
        type: 'GET',
        dataType: 'json',
        error: function (e) {
          window.location.href = '/' + I18n.currentLocale() + '/associations/'+ association_id + '/team_users/' + team_id +  '/administrators';
        },
        success: function (e) {
          window.location.href = '/' + I18n.currentLocale() + '/associations/'+ association_id + '/team_users/' + team_id + '/administrators';
        }
      })
    }
  })
  var swal_popup = document.querySelector('.swal2-popup');
  if (swal_popup) {
    swal_popup.classList.add('info-box');
  }
});


$('.single-team-editor-delete-button').on('click', function (e) {
  var team_id = $(this).data('team_id');
  let confirm_heading = document.getElementById('administrator_confirm_heading').innerHTML
  let confirm_text = document.getElementById('administrator_confirm_text').innerHTML
  Swal.fire({
    title: $(this).data('confirm_confirm'),
    confirmButtonText: $(this).data('confirm_sign'),
    confirmButtonColor: '#4d7de1',
    cancelButtonColor: '#DB4437',
    showCancelButton: true,
    cancelButtonText: $(this).data('confirm_cancel'),
    html: confirm_heading + '<br />' + confirm_text,
    icon: 'warning',
  }).then((result) => {
    if (result.value) {
      $.ajax({
        url: '/' + I18n.currentLocale() + '/team_editors/' + team_id + '/delete_editor/?editor_id=' +  $(this).data('editor_id'),
        type: 'GET',
        dataType: 'json',
        error: function (e) {
          window.location.href = '/' + I18n.currentLocale() + '/team_editors/'+ team_id +  '/administrators';
        },
        success: function (e) {
          window.location.href = '/' + I18n.currentLocale() + '/team_editors/'+ team_id +  '/administrators';
        }
      })
    }
  })
  var swal_popup = document.querySelector('.swal2-popup');
  if (swal_popup) {
    swal_popup.classList.add('info-box');
  }
});


$('.team-member-delete-button').on('click', function (e) {
  var association_id = $(this).data('association_id');
  var team_id = $(this).data('team_id');
  let confirm_heading = document.getElementById('administrator_confirm_heading').innerHTML
  let confirm_text = document.getElementById('administrator_confirm_text').innerHTML
  Swal.fire({
    title: $(this).data('confirm_confirm'),
    confirmButtonText: $(this).data('confirm_sign'),
    confirmButtonColor: '#4d7de1',
    cancelButtonColor: '#DB4437',
    showCancelButton: true,
    cancelButtonText: $(this).data('confirm_cancel'),
    html: confirm_heading + '<br />' + confirm_text,
    icon: 'warning',
}).then((result) => {
  if (result.value) {
    $.ajax({
      url: '/' + I18n.currentLocale() + '/associations/'+ association_id + '/teams/' + team_id + '/team_members/delete_member?member_id=' +  $(this).data('member_id'),
      type: 'GET',
      dataType: 'json',
      error: function (e) {
        window.location.href = '/' + I18n.currentLocale() + '/associations/'+ association_id + '/teams/' + team_id +  '/team_members';
      },
      success: function (e) {
        window.location.href = '/' + I18n.currentLocale() + '/associations/'+ association_id + '/teams/' + team_id + '/team_members';
      }
    })
  }
})
var swal_popup = document.querySelector('.swal2-popup');
if (swal_popup) {
  swal_popup.classList.add('info-box');
}
});

});