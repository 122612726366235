$(document).on('turbolinks:load', function () {
  // $('#country-select option').first().hide();

  // var code = '';
  // var country_name = '';

  // function sleep(ms) {
  // return new Promise(res => setTimeout(res, ms));
  // }


  // async function executeLine() {
  // $.get("https://ipinfo.io", function(response) {
  //     code = response.country;
  // }, "jsonp");

  // await sleep(500);

  // let regionNames = new Intl.DisplayNames(['en'], {type: 'region'});
  // var country_name = regionNames.of(code);


  // var selectList = document.getElementById('country-select');
  // var option = document.createElement("option");
  // option.text = country_name;
  // option.value = code;
  // option.title = country_name;
  // if(selectList){
  //   selectList.insertBefore(option, selectList.options[1]);
  // }
  // }
  // executeLine()

});