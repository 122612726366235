// $(document).on('#confirm-btn:click', function() {
//   $('#loading').show();
//   // show spinner
// });

// $(document).on('turbolinks:load', function() {
//   $('#loading').hide();
//   // hide spinner
// });
$(document).on("turbolinks:load", function () {
  $('.submit-subs').on('click', function(e){
    e.preventDefault();
    var amount = $(this).data('amount');
    var interval = $(this).data('interval');
    var country = $(this).data('country');
    $('#confirm-btn').text(I18n.t("team_subscription.confirm.confirm") +
                            I18n.t("subscription_price." + country + "_amount",
                              {amount: amount}
                            ) +
                            "/" +
                            I18n.t("subscription_price." + interval)
                          );
    $('#confirm-btn').data('price-id', $(this).data('price-id'))
  })
  $('#confirm-btn').on('click',function(e){
    $('#confirm').modal('hide');
    $('#loading').modal({show: true});
    $('#team_subscription_form_' + $('#confirm-btn').data('price-id')).submit();
  })
})
