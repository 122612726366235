import Swal from 'sweetalert2'

$(document).on('turbolinks:load', function () {


     // Get the select element
     var selectTeams = $('#sports_select_id2');
     if(document.getElementById('sports_select_id2')){

        // Initialize multiselect plugin
         $('#sports_select_id2').multiselect({});
   
        // Select the "All" option by default
        selectTeams.val('0');
        selectTeams.find('option[value!=""]').prop('selected', true);
        selectTeams.multiselect('refresh');
        
        var previouslySelectedOptions = [];
        var currentlySelectedOptions = selectTeams.val();

        var optionsAdded = currentlySelectedOptions.filter(option => !previouslySelectedOptions.includes(option));
        var optionsRemoved = previouslySelectedOptions.filter(option => !currentlySelectedOptions.includes(option));
        previouslySelectedOptions = currentlySelectedOptions.slice();

        // Function to handle the change event of the select box
        selectTeams.on('change', function(event) {
        console.log('Change event triggered');
        // Get the selected option
        var selectedOption = $(this).val();
        currentlySelectedOptions = $(this).val();
        
        optionsAdded = currentlySelectedOptions.filter(option => !previouslySelectedOptions.includes(option));
        optionsRemoved = previouslySelectedOptions.filter(option => !currentlySelectedOptions.includes(option));
        previouslySelectedOptions = currentlySelectedOptions.slice();
        
        // Log the IDs of the options that were added and removed
        console.log('Options added:', optionsAdded);
        console.log('Options removed:', optionsRemoved);
        // If the "All" option is selected, select all other options
        if (optionsAdded.length == 1 && optionsAdded[0] == '0'){
            console.log("All selected");
            selectTeams.find('option[value!=""]').prop('selected', true);
        }else if(optionsRemoved.length == 1 && optionsRemoved[0] == '0'){
            // If the "All" option is deselected, deselect all other options
            console.log("All removed");
            selectTeams.find('option[value!="0"]').prop('selected', false);
        }else if(optionsRemoved.length == 1 && optionsRemoved[0] != '0' && selectedOption.includes('0')){
            selectTeams.find('option[value="0"]').prop('selected', false);
        }
        selectTeams.multiselect('refresh');
        });
     }

    // show swal popup for message center message creation

    var messageSentAssociation = document.getElementById('message-sent-association');
    if (messageSentAssociation ) {
        if(messageSentAssociation.textContent.trim() === 'yes'){
            Swal.fire({
                title: I18n.t('associations.message_center.fantastic'),
                html: get_context(false),
                confirmButtonColor: '#4d7de1',
                cancelButtonColor: '#DB4437',
                showConfirmButton: false, // Set to false to hide the default confirmation button
                allowOutsideClick: true, // Prevent closing by clicking outside the popup
                customClass: {
                  popup: 'new-result-swal'
                },
                onOpen: (modalElement) => {
                  // Attach event listeners to your custom buttons
                  document.getElementById('closeButton').addEventListener('click', () => {
                    Swal.close();
                  });
                },
            });
        }
    }

    var messageSentAll = document.getElementById('message-sent-all');
    if (messageSentAll ) {
        if(messageSentAll.textContent.trim() === 'yes'){
            Swal.fire({
                title: I18n.t('associations.message_center.fantastic'),
                html: get_context(true),
                confirmButtonColor: '#4d7de1',
                cancelButtonColor: '#DB4437',
                showConfirmButton: false, // Set to false to hide the default confirmation button
                allowOutsideClick: true, // Prevent closing by clicking outside the popup
                customClass: {
                  popup: 'new-result-swal'
                },
                onOpen: (modalElement) => {
                  // Attach event listeners to your custom buttons
                  document.getElementById('closeButton').addEventListener('click', () => {
                    Swal.close();
                  });
                },
            });
        }
    }


    function get_context(v2){
        
        if(v2){
            var context1 = I18n.t('associations.message_center.mails_sentv2')
            return '<div style="margin-top: 1.3rem;">' + 
            '<p>' + context1 +'</p>' + 
            '<button id="closeButton"><i class="fa fa-times" aria-hidden="true"></i></button>'+
            '</div>'
        }else{
            var context1 = I18n.t('associations.message_center.message_is_sent')
            return '<div style="margin-top: 1.3rem;">' + 
            '<p>' + context1 +'</p>' + 
            '<button id="closeButton"><i class="fa fa-times" aria-hidden="true"></i></button>'+
            '</div>'
        }
    }


    function editUserEmail(userId, userEmail, button) {
        // Show SweetAlert popup with input field pre-filled with user's email
        Swal.fire({
            title: I18n.t('onboarding.step4.edit_email'),
            input: 'email',
            inputPlaceholder: I18n.t('onboarding.step4.enter_new_mail'),
            inputValue: userEmail, // Pre-fill the input field with the user's email
            showCancelButton: true,
            confirmButtonText: I18n.t('onboarding.step4.save'),
            cancelButtonText: I18n.t('onboarding.step4.cancel'),
            showLoaderOnConfirm: true,
            preConfirm: () => {
                // No need to perform AJAX request here, it will be triggered when "Save" is clicked
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            // Handle response from SweetAlert popup
            if (!(result.dismiss == 'cancel')) {
                // Retrieve the updated email value from the SweetAlert input field
                var newEmail = Swal.getPopup().querySelector('input').value;
    
                // Perform AJAX request to update user's email
                $.ajax({
                    url: '/associations/update_email', // Replace with your actual route
                    method: 'POST',
                    data: {
                        user_id: userId,
                        email: newEmail
                    },
                    success: function(response) {
                        // Handle successful response
                        if (response.success) {
                            // Show success message
                            button.setAttribute('data-email', newEmail);
                            button.style.opacity = '100%';
                            // Update the data-email attribute of the button
                            Swal.fire({
                                title: I18n.t('onboarding.step4.success'),
                                text: I18n.t('onboarding.step4.email_updated'),
                                icon: 'success'
                            });
    
                        } else {
                            // Show error message
                            Swal.fire({
                                title: I18n.t('onboarding.step4.error'),
                                text: response.error,
                                icon: 'error'
                            });
                        }
                    },
                    error: function(xhr, status, error) {
                        // Show error message if AJAX request fails
                        Swal.fire({
                            title: I18n.t('onboarding.step4.error'),
                            text:  I18n.t('onboarding.step4.failed_to_update') + xhr.responseJSON.error,
                            icon: 'error'
                        });
                    }
                });
            }
        });
    }
    
    const editButtons = document.querySelectorAll('.edit-user-mail');

    editButtons.forEach((button) => {
        const user_id = button.getAttribute('data-user-id');
    
        button.addEventListener('click', () => {
            // Fetch the current email value directly from the button element
            const email = button.getAttribute('data-email');
            editUserEmail(user_id, email, button);
        });
    });


    var step5SubmitButton = document.getElementById('step5-submit-button');

    // Check if the button element exists
    if (step5SubmitButton) {
        // Add a click event listener to the button
        step5SubmitButton.addEventListener('click', function() {
            // Call the goToNextStep7 function when the button is clicked
            goToNextStep6();
        });
    }


    function goToNextStep6() {
        // Check if element with id 'on_step6' exists
        var onStep6Element = document.getElementById('on_step5');
        var association_id = document.getElementById('association_id').innerHTML;
        
        if (onStep6Element && onStep6Element.innerHTML.trim().toLowerCase() === 'yes') {
            // Check if element with id 'stripe_connected' exists
            var stripeConnectedElement = document.getElementById('stripe_connected');
            
            if (stripeConnectedElement && stripeConnectedElement.innerHTML.trim().toLowerCase() === 'no') {
                // If 'stripe_connected' is 'no', show swalalert to connect Stripe account
                swalalert(I18n.t('onboarding.step5.please_connect_wallet'));
            } else {
                // Otherwise, proceed with the original code inside goToNextStep7
                var revenueSelectedValue = document.getElementById('team_revenue_button').value;
                var allowAccount = document.getElementById('allow_team_bank_account_yes').checked;
    
                // Check if a value is selected before redirecting
                window.location.href = `/associations/${association_id}/onboarding/step6?revenue=${revenueSelectedValue}&allow_account=${allowAccount}`;
            }
        } else {
            // Handle the case when 'on_step6' is not present
            console.error("Element with id 'on_step6' not found or has an unexpected value.");
        }
    }

    function swalalert(message)
    {
      Swal.fire({
        icon: "error",
        title: (I18n.t('associations.layout.error')),
        text: message
      });
    }
  


    // Check if the button with the class exists
    const backButton = document.querySelector('.back-button');

    // Add event listener only if backButton is not null (i.e., the button with the class exists)
    if (backButton) {
        backButton.addEventListener('click', function() {
            // Navigate to the previous page
            window.history.back();
        });
    }
    
    var errorsElement = document.getElementById('csv-errors');

    if (errorsElement) {
        // Parse the JSON-encoded errors
        var errorsJson = errorsElement.innerHTML;
        var errorsData;

        try {
            errorsData = JSON.parse(errorsJson);
        } catch (error) {
            // If JSON parsing fails, treat it as a regular string
            errorsData = { 'Unknown Error': [errorsJson] };
        }

        // Generate HTML for the table
        var tableHTML = '<table class="table table-bordered">';

        // Iterate through each team and its errors
        for (var teamName in errorsData) {
            if (errorsData.hasOwnProperty(teamName)) {
                var teamErrors = errorsData[teamName];

                // Create a Set to store unique error messages for the team
                var uniqueErrors = new Set(teamErrors);

                // Add team name as a row in the table
                tableHTML += '<tr><td><strong>' + teamName + '</strong></td></tr>'; 
                 // Concatenate unique errors with line breaks
                 var concatenatedErrors = Array.from(uniqueErrors).join('<br>');

                 // Check if the concatenated string is valid JSON before attempting to parse
                 var isJson = isJsonString(concatenatedErrors);
 
                 // Add each unique error message for the team
                 if (isJson) {
                     var jsonErrors = JSON.parse(concatenatedErrors);
                     for (var key in jsonErrors) {
                         if (jsonErrors.hasOwnProperty(key)) {
                             tableHTML += '<tr><td>' + key + ': ' + jsonErrors[key] + '</td></tr>';
                         }
                     }
                 } else {
                     tableHTML += '<tr><td>' + concatenatedErrors + '</td></tr>';
                 }
                
            }
        }

        tableHTML += '</table>';

        // Show SweetAlert with the table
        Swal.fire({
            title: "Some errors occurred",
            showConfirmButton: true,
            html: tableHTML
        });
    }

    // Function to check if a string is valid JSON
    function isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
});
