import { debounce } from "./helper";
import Swal from 'sweetalert2'

$(document).on('turbolinks:load', function () {
  $('[data-toggle="tooltip"]').tooltip();

  $('.events-select').on('change', function () {
    $('#custom_info_message').addClass('d-none');
    ['event_sponsorship', 'monthly_sponsorship', 'single_sponsorship', 'training_fee_sponsorship', 'membership_sponsorship'].map(function (elem) {
      $('.event-type-info-placeholder.' + elem).addClass('d-none');
    });

    $('.select_your_amount').addClass('d-none event-options-container');
    $('.select_your_membership').addClass('d-none event-options-container');
    $('.amount-selector').addClass('d-none event-type-value-container base-value-container');
    $('.anonymous-check').addClass('d-none');
    $('.membership_title').addClass('d-none');
    $('#membership_title_heading').text('');

    if($(this).find(':selected').data('event-type') == "monthly"){
      $('.event-type-info-placeholder.monthly_sponsorship').removeClass('d-none');
      $('.select_your_amount').removeClass('d-none');
      $('.select_your_membership').removeClass('event-options-container');
      $('.anonymous-check').removeClass('d-none');
    }
    else if($(this).find(':selected').data('event-type') == "one_time") {
      $('.event-type-info-placeholder.single_sponsorship').removeClass('d-none');
      $('.select_your_amount').removeClass('d-none');
      $('.select_your_membership').removeClass('event-options-container');
      $('.anonymous-check').removeClass('d-none');
    }
    else if($(this).find(':selected').data('event-type') == "training_fee") {
      $('.event-type-info-placeholder.training_fee_sponsorship').removeClass('d-none');
      $('.select_your_membership').removeClass('d-none');
      $('.select_your_amount').removeClass('event-options-container');
      $('.amount-selector').removeClass('d-none event-type-value-container base-value-container');
      $('.amount-selector').addClass('d-none');
      $('.membership_title').removeClass('d-none');
      $('#membership_title_heading').text($(this).find(':selected').data('training-fee-heading'));

    }
    else if($(this).find(':selected').data('event-type') == "membership") {
      $('.event-type-info-placeholder.membership_sponsorship').removeClass('d-none');
      $('.select_your_membership').removeClass('d-none');
      $('.select_your_amount').removeClass('event-options-container');
      $('.amount-selector').removeClass('d-none event-type-value-container base-value-container');
      $('.amount-selector').addClass('d-none');
      $('.membership_title').removeClass('d-none');
      $('#membership_title_heading').text($(this).find(':selected').data('membership-heading'));
    }
    else {
      $('.event-type-info-placeholder.event_sponsorship').removeClass('d-none');
      $('.select_your_amount').removeClass('d-none');
      $('.select_your_membership').removeClass('event-options-container');
      $('.anonymous-check').removeClass('d-none');
    }
  })

  $('.sponsorship-type-select').on('change', function () {
    $('.event-select-container').addClass('d-none')
    $('.image-and-description').addClass('d-none')
    $('.base-value-container').addClass('d-none')
    $('.event-type-value-container').addClass('d-none')
    $('label[for=sponsorship_custom_amount]').text($('label[for=sponsorship_custom_amount]').data('default-text'));
    let sponsorship_type = this;
    ['event_sponsorship', 'monthly_sponsorship', 'single_sponsorship'].map(function (elem) {
      if ($(sponsorship_type).val() === elem) {
        $('.event-type-info-placeholder.' + elem).removeClass('d-none');
      }
      else {
        $('.event-type-info-placeholder.' + elem).addClass('d-none');
      }
    });

    if ($(this).val() === '') {
      $('#sponsorship_event_id').val('');
      return;
    }

    if ($(this).val() === 'event_sponsorship') {
      $('.event-select-container').removeClass('d-none')
      $('.event-options-container').show()
    } else {
      $('#sponsorship_event_id').val('');

      $('.event-options-container').hide()
      $('.base-value-container').removeClass('d-none')

      $('#sponsorship_custom_amount').attr('placeholder', I18n.t('helpers.placeholders.sponsorship.custom_amount'))
      $('#sponsorship_custom_amount').attr('min', 0)

      $('.amount-info').show();
      $('.base-value-container .event_option').removeClass('bg-light border p-3 styled-event-option')
    }
  })

  if ($('.sponsorship-type-select').val() !== "") {
    $('.sponsorship-type-select').trigger('change')
  }

  $('.event_option').on('click', function (e) {
    $('.event_option').removeClass('active')
    $(e.currentTarget).addClass('active')
    $('#sponsorship_amount').val($('#sponsorship_custom_amount').val())

    if ($(e.currentTarget).find('input').length && $('.event_option').length > 1) {
      $('#sponsorship_with_fee').val('true')
    }
  })

  $('#sponsorship_event_id').on('change', function () {
    $('.base-value-container').removeClass('d-none')
    if ($(this).val() === '') {
      $('.event-type-value-container').addClass('d-none')
      return
    }

    $('.image-and-description').addClass('d-none')
    $(`.image-and-description[data-target="#event-box-${$(this).val()}"]`).removeClass('d-none')

    var width = $('.col-md-12').width()
    var percent = parseInt($(`.image-and-description[data-target="#event-box-${$(this).val()}"]`).data('percent'))
    var percent_for_image = 100 - percent + '%'
    if (percent > 90){
      percent = '5%'
    }else{
      percent = 100 - percent - 5 + '%'
    }
    $(`.image-and-description[data-target="#event-box-${$(this).val()}"] .second-opacity`).height(percent_for_image)
    $(`.image-and-description[data-target="#event-box-${$(this).val()}"] .second-opacity`).width(width)
    $(`.image-and-description[data-target="#event-box-${$(this).val()}"] .bk-ground-white`).width(width)
    $(`.image-and-description[data-target="#event-box-${$(this).val()}"] .total`).css('top',percent)
    $(`.image-and-description[data-target="#event-box-${$(this).val()}"] .percent`).css('top',percent)


    $.ajax({
      url: '/' + I18n.currentLocale() + '/teams/' + $('#sponsorship_team_id').val() + '/events/' + $(this).val(),
      type: 'GET',
      dataType: 'json',
      error: function (xhr, status, error) {
        console.error('AJAX Error: ' + status + error)
      },
      success: function (response) {
        $('.event-type-value-container').removeClass('d-none')
        $('label[for=sponsorship_custom_amount]').text(response.text)

        $('.event-options-container .event_option').remove()
        $('.event-options-container:not(.d-none)').addClass('d-none')


        if(response.event_type == "custom"){
          $('#custom_value_p').text(response.unit_variable);
          $('#custom_user_info_id').removeClass('d-none');
           
        }
        else{
          $('#custom_value_p').text("");
          $('#custom_user_info_id').addClass('d-none');
        }

      if(response.user_info != null){

        $('#custom_user_info_id').removeClass('d-none');
        if(response.user_info.name == true)
        { 
          $('#custom_info_message').removeClass('d-none');
          $('#form-name').removeClass('d-none');
          if(response.user_info.name_required == true){
            $('#form-name').attr("required", "true");
          }else{
            var name = $('#form-name').attr('placeholder');
            $('#form-name').attr('placeholder', name.split("*")[0]);
            $('#form-name').removeAttr('required');
          }
        }
        else{ $('#form-name').addClass('d-none');                  
      }

        if(response.user_info.address == true)
        { 
          $('#form-address').removeClass('d-none');
          $('#form-zip').removeClass('d-none');
          $('#form-city').removeClass('d-none');
          $('#custom_info_message').removeClass('d-none');
          if(response.user_info.address_required == true){
            $('#form-address').attr("required", "true");
            $('#form-zip').attr("required", "true");
            $('#form-city').attr("required", "true");
          }else{
            $('#form-address').removeAttr('required');
            $('#form-zip').removeAttr('required');
            $('#form-city').removeAttr('required');
            var address = $('#form-address').attr('placeholder');
            $('#form-address').attr('placeholder', address.split("*")[0]);
            var zip = $('#form-zip').attr('placeholder');
            $('#form-zip').attr('placeholder', zip.split("*")[0]);
            var city = $('#form-city').attr('placeholder');
            $('#form-city').attr('placeholder', city.split("*")[0]);
          }
        }
        else
        { 
          $('#form-address').addClass('d-none');
          $('#form-zip').addClass('d-none');
          $('#form-city').addClass('d-none');
        }

        if(response.user_info.phone == true)
        { 
          $('#custom_info_message').removeClass('d-none');

          $('#form-phone_number').removeClass('d-none');
          if(response.user_info.phone_required == true){
            $('#form-phone_number').attr("required", "true");
          }else{
            var phone_number = $('#form-phone_number').attr('placeholder');
            $('#form-phone_number').attr('placeholder', phone_number.split("*")[0]);
            $('#form-phone_number').removeAttr('required');
          }
        }
        else{ $('#form-phone_number').addClass('d-none');                  
      }

        
        if(response.user_info.mail == true)
        { 
          $('#custom_info_message').removeClass('d-none');

          $('#form-mail').removeClass('d-none');
          if(response.user_info.mail_required == true){
            $('#form-mail').attr("required", "true");
          }else{
            var mail = $('#form-mail').attr('placeholder');
            $('#form-mail').attr('placeholder', mail.split("*")[0]);
            $('#form-mail').removeAttr('required');
          }
        }
        else{ $('#form-mail').addClass('d-none');                  
      }
        
        if(response.user_info.member_id == true)
        { 
          $('#custom_info_message').removeClass('d-none');

          $('#form-member_id').removeClass('d-none');
          if(response.user_info.member_id_required == true){
            $('#form-member_id').attr("required", "true");
          }else{
            var member_id = $('#form-member_id').attr('placeholder');
            $('#form-member_id').attr('placeholder', member_id.split("*")[0]);
            $('#form-member_id').removeAttr('required');
          }
        }
        else{ $('#form-member_id').addClass('d-none');                  
      }

        if(response.user_info.social_security == true)
        { 
          $('#custom_info_message').removeClass('d-none');

          $('#form-social_security').removeClass('d-none');
          if(response.user_info.social_security_required == true){
            $('#form-social_security').attr("required", "true");
          }else{
            var social_security = $('#form-social_security').attr('placeholder');
            $('#form-social_security').attr('placeholder', social_security.split("*")[0]);
            $('#form-social_security').removeAttr('required');
          }
        }
        else{ $('#form-social_security').addClass('d-none');                  
      }
        

        if(response.user_info.other == true)
        { 
          $('#custom_info_message').removeClass('d-none');

          if(response.user_info.other_text != ''){
            $('#form-other').attr("placeholder", response.user_info.other_text + "*");
          }else{
            $('#form-other').attr("placeholder", "Other*");
          }
          $('#form-other').removeClass('d-none');

          if(response.user_info.other_required == true){
            $('#form-other').attr("required", "true");
          }else{
            var other = $('#form-other').attr('placeholder');
            $('#form-other').attr('placeholder', other.split("*")[0]);
            $('#form-other').removeAttr('required');
          }

        }
        else
        { 
          $('#form-other').addClass('d-none');
        }
      }


        if (response.options.length > 0) {
          $('.event-options-container').removeClass('d-none')
          response.options.forEach(function (option) {
            (function (option) {
              $('.event-options-container').append($(`
                <div class="event_option bg-light border mt-2 p-3 d-block styled-event-option">
                  <div class="d-flex justify-content-between">
                    <h4>${option.header}</h4>
                    <h4>${option.formatted_amount_with_fee}</h4>
                  </div>
                  <div class="text-left">
                    <p class="m-0">${option.description}</p>
                  </div>
                </div>
              `).on('click', function (e) {
                $('.event_option').removeClass('active')
                $(e.currentTarget).addClass('active')
                $('#sponsorship_amount').val(option.amount)

                if ($(e.currentTarget).not(":has(input)").length) {
                  $('#sponsorship_with_fee').val('false')
                }
              }))
            }(option))
          })

          $('#sponsorship_custom_amount').attr('placeholder', response.formatted_min_amount)
          $('#sponsorship_custom_amount').attr('min', response.min_amount)

          $('.amount-info').hide();
          $('.base-value-container .event_option').addClass('bg-light border p-3 styled-event-option')

          $('#sponsorship_with_fee').val('true')
        } else {
          $('#sponsorship_custom_amount').attr('placeholder', response.custom_amount_text)
          $('#sponsorship_custom_amount').attr('min', response.min_amount)

          $('.amount-info').show();
          $('.base-value-container .event_option').removeClass('bg-light border p-3 styled-event-option')

          $('#sponsorship_with_fee').val('false')
        }
        if (!document.getElementById('additional_amount')){
          if (response.minimum_amount > 0) {
            $('#sponsorship_amount').attr(
              { 'min': (response.minimum_amount / 100), 'value': (response.minimum_amount / 100) }
            )
          } else {
            const limit = window._amount_lower_limit
            $('#sponsorship_amount').attr({ 'min': limit, 'value': limit })
          }
        }
        blur_helper_method();
      }
    })
  })

  if ($('#sponsorship_event_id').val() !== "") {
    $('#sponsorship_event_id').trigger('change')
  }

  $('#sponsorship_total_amount_limit').keyup(function (event) {
    const limit = window._sponsorship_lower_limit
    if (this.value < limit) $('#sponsorship_total_amount_limit').attr({ 'min': limit })
    if (this.value == 0) $('#sponsorship_total_amount_limit').attr({ 'min': 0 })
  })

  $('#sponsorship_custom_amount').on('input', debounce(function () {
    $.ajax({
      url: '/' + I18n.currentLocale() + '/calculate_fee/?amount=' + $(this).val() + '&team_id=' + $('#sponsorship_team_id').val(),
      type: 'GET',
      dataType: 'json',
      error: function (xhr, status, error) {
        console.error('AJAX Error: ' + status + error)
        $('#amount-including-fee').text('')
      },
      success: function (response) {
        $('#amount-including-fee').text(($('#amount-including-fee').data('total-text')) + ': ' + response.money)
      }
    })
    if ($('.event_option').length > 1) {
      $('#sponsorship_with_fee').val('true')
    } else {
      $('#sponsorship_with_fee').val('false')
    }
    $('#sponsorship_amount').val($(this).val())
  }, 250))

  $('.confirm-cancel-sponsorship-button').on('click', function (event) {
    event.preventDefault();
    const sponsorship_id = $(this).data('sponsorship-id');

    Swal.fire({
      title: $(this).data('confirm-confirm'),
      confirmButtonText: $(this).data('confirm-sign'),
      confirmButtonColor: '#4d7de1',
      cancelButtonColor: '#DB4437',
      showCancelButton: true,
      cancelButtonText: $(this).data('confirm-cancel'),
      html: $(this).data('confirm-heading'),
      icon: 'warning',
    }).then(function (result) {
      if (result.value) {
        $('#sponsorship-container-' + sponsorship_id).find('form').submit();
      }
    });
    var swal_popup = document.querySelector('.swal2-popup');
    if (swal_popup) {
      swal_popup.classList.add('info-box');
    }
  })

  $('.add-message').on('click', function(event){
    $(this).find('.input-message').removeClass('d-none')

    $(this).find('#send').on('click', function(event){
      var id = $(this).parent('.input-message').data('sponsorship-id')
      var message = $(this).parent('.input-message').find('textarea').val()
      $.ajax({
        url: '/' + I18n.currentLocale() + '/sponsorships/add_message/?id='+ id +'&message=' + message,
        type: 'PATCH',
        dataType: 'json',
        success: function (response) {
          window.location.reload()
        }
      })
    })
  })
})
