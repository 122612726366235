$(document).on('turbolinks:load', function () {
  $('.modal-color_picker').prop('disabled', false);

  $('.edit_theme').on('change', function(e, changed){
    if (changed || changed == undefined) {
      $(this).closest('form').submit();
    }
  })

  $('.new_theme').on('change', function(e){
    $(this).closest('form').submit();
  })

  $( ".theme-modal" ).on('shown.bs.modal', function(){
    $('.modal-color_picker').minicolors({inline: true});
  });

  $('.color-section-radio-btn').on('change', function(e){
    $('.modal-color_picker').prop('disabled', "disabled");
  })
})

// new
$(function(){
  $('.bkg-img-section .bkg-img-sec-btn').hide();
  $('.bkg-img-section .bkg-img-sec-btn:nth-child(n+1):nth-child(-n+8)').show();

  // $('.bkg-img-section .bkg-img-sec-btn:nth-child(7), .bkg-img-sec-btn:nth-child(8)').css({"opacity":"0.5"});

  $('.ShowMoreBtn').click(function(e){
    e.preventDefault();
    $(this).closest('.bkg-img-section').find('.bkg-img-sec-btn:not(:visible)').show();
    $('.bkg-img-section .bkg-img-sec-btn:nth-child(7), .bkg-img-section .bkg-img-sec-btn:nth-child(8)').css({"opacity":"1"});
    $(this).hide();
  })
})
