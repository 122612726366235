// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
//= require jquery
//= require jquery.minicolors

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)
import I18n from 'i18n-js';
window.I18n = I18n
import "bootstrap/dist/js/bootstrap";
import "@fortawesome/fontawesome-free/js/all";
import "cocoon-js";
import "../stylesheets/application.scss";
import '../js/google-analytics';
import '../js/helper';
import '../js/slider';
import '../js/copy-team-link';
import '../js/bootstrap';
import '../js/flatpickr';
import '../js/stripe';
import '../js/sponsorship';
import '../js/menu';
import '../js/event-results';
import '../js/events';
import '../js/recruiter';
import '../js/associations';
import '../js/payment_method';
import '../js/minicolors';
import '../js/theme';
import '../js/team_subscription'
import '../js/team'
if (navigator.serviceWorker) {
  navigator.serviceWorker.register('/service-worker.js', { scope: './' })
    .then(function(reg) {
      console.log('[Companion]', 'Service worker registered!');
      console.log(reg);
    });
}
