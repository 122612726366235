import Swal from 'sweetalert2'

$(document).on('turbolinks:load', function () {
  $('.confirm-remove-payment-button').on('click', function (event) {
    event.preventDefault();

    Swal.fire({
      title: $(this).data('confirm-confirm'),
      confirmButtonText: $(this).data('confirm-sign'),
      confirmButtonColor: '#4d7de1',
      cancelButtonColor: '#DB4437',
      showCancelButton: true,
      cancelButtonText: $(this).data('confirm-cancel'),
      html: $(this).data('confirm-heading'),
      icon: 'warning',
    }).then(function (result) {
      if (result.value) {
        $('.payment-method-remove').find('form').submit()
      }
    });
    var swal_popup = document.querySelector('.swal2-popup');
    if (swal_popup) {
      swal_popup.classList.add('info-box');
    }
  })
})
