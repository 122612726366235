import flatpickr from 'flatpickr';
import { Swedish } from 'flatpickr/dist/l10n/sv.js'

require('flatpickr/dist/flatpickr.css')

$(document).on('turbolinks:load', function () {
  flatpickr("[data-behavior='flatpickr']", {
    altInput: true,
    altFormat: 'Y-m-d',
    dateFormat: 'Y-m-d',
    mode: $(this).data('mode'),
    minDate: $(this).data('min-date') || null,
    maxDate: $(this).data('max-date') || null,
    locale: Swedish
  })
})
