import Swal from 'sweetalert2'

$(document).on('click', '.copy-link-btn', function () {
  var copyText = document.getElementById('team-link');
  copyText.type = 'text';
  copyText.select();
  copyText.setSelectionRange(0, 99999);
  document.execCommand("copy");
  copyText.type = 'hidden';
})

$(document).on('click', '.copy-event-link-btn', function (e) {
  e.stopPropagation();
  var copyText = $(e.currentTarget).prev()[0];
  copyText.type = 'text';
  copyText.select();
  copyText.setSelectionRange(0, 99999);
  document.execCommand("copy");
  copyText.type = 'hidden';
})

$(document).on('click', '.share-event-link-btn', function (e) {
  e.stopPropagation();
  const shareButton = $(this); 
  const fundraiserName = shareButton.data('event-name'); 
  if (navigator.share) {
    // Web Share API is supported
    try {
      navigator.share({
        title: `Support the ${fundraiserName} Fundraiser on Sportpengen!`,
        text: `Join us in making a difference by supporting the ${fundraiserName} fundraiser. Every contribution counts!`,
        url: shareButton.data('event-url')
      }).then(() => {
        console.log('Content shared successfully');
      }).catch((error) => {
        console.error('Error sharing content:', error.message);
      });
    } catch (error) {
      console.error('Error sharing content:', error.message);
    }
  } else {
    // Fallback for browsers that do not support Web Share API
    shareSwalPopup(shareButton.data('event-url'), fundraiserName)
  }
});



function shareSwalPopup(url, event_name){
  Swal.fire({
    title: ` `,
    icon: 'none',
    html: `
      <p>Share the fundraiser ${event_name} with your friends:</p>
      <hr>
      <br>
      <div class="btn-group-vertical" style="width: 100%; display: flex; flex-direction: row; justify-content: space-evenly;">
        <button type="button" id="facebookBtn" class="" style="border: none; background: none;">
          <i class="fab fa-facebook-square" style="font-size: 4rem;"></i>
        </button>
        <button type="button" id="twitterBtn" class="" style="border: none; background: none;">
          <i class="fab fa-twitter-square" style="font-size: 4rem;"></i>
        </button>
        <button type="button" id="emailBtn" class="" style="border: none; background: none;">
          <i class="fas fa-envelope-square" style="font-size: 4rem;"></i>
        </button>
      </div>
      <br>
    `,
    showCloseButton: true,
    showConfirmButton: false
  });

  // Handle button clicks
  $('#facebookBtn').click(() => {
    const fundraiserName = $('#shareButton').data('event-name');
    const url = $('#shareButton').data('event-url');
    // const text = `Join us in making a difference by supporting the ${fundraiserName} fundraiser on Sportpengen. Every contribution counts!`;
    const facebookUrl = `https://www.facebook.com/sharer.php?u=${encodeURIComponent(url)}`;
    window.open(facebookUrl, '_blank');
  });
  
  
  $('#twitterBtn').click(() => {
    const fundraiserName = $('#shareButton').data('event-name');
    const url = $('#shareButton').data('event-url');
    const text = `Join us in making a difference by supporting the ${fundraiserName} fundraiser on Sportpengen. Every contribution counts!`;
    const twitterUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`;
    window.open(twitterUrl, '_blank');
  });
  
  $('#emailBtn').click(() => {
    const fundraiserName = $('#shareButton').data('event-name');
    const url = $('#shareButton').data('event-url');
    const text = `Join us in making a difference by supporting the ${fundraiserName} fundraiser. Every contribution counts!`;
    const subject = `Support the ${fundraiserName} Fundraiser on Sportpengen!`;
    const body = `Check out this fundraiser:\n${url}\n\n${text}`;
    const mailtoUrl = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoUrl;
  });
}

