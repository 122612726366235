$(document).on("turbolinks:load", function () {
  if ($('#card-element').length) {
    // Create a Stripe client.
    const stripe = Stripe(window._stripe_publishable_key);

    // Create an instance of Elements.
    const elements = stripe.elements();

    // Custom styling can be passed to options when creating an Element.
    // (Note that this demo uses a wider set of styles than the guide below.)
    const style = {
      base: {
        color: '#333333',
        lineHeight: '1.429',
        fontFamily: '"Roboto", "Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#495057'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    };

    // Create an instance of the card Element.
    const card = elements.create('card', { style: style, hidePostalCode: true });

    // Add an instance of the card Element into the `card-element` <div>.
    card.mount('#card-element');

    // Handle real-time validation errors from the card Element.
    card.addEventListener('change', function (event) {
      const displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });

    $('#new-payment-method-form').on('submit', function (event) {
      event.preventDefault();
      $("#add-payment-method-btn").attr("disabled", true);
      $("#add-payment-method-btn").find('i').show();
      stripeHandlePayment(event);
      return false;
    })


    function stripeHandlePayment(event) {
      event.preventDefault();

      const clientSecret = $('#add-payment-method-btn').data("secret")

      stripe.confirmCardSetup(
        clientSecret,
        {
          payment_method: {
            card: card
          }
        }
      ).then(function (result) {
        if (result.error) {
          stripeHandleError(result, $("#add-payment-method-btn"))
        } else {
          stripeResultHandler(result);
        }
      })
    }

    // Send Stripe Payment Method to Server
    function stripeResultHandler(result) {
      // Insert the payment method into the form so it gets submitted to the server
      const form = document.getElementById('new-payment-method-form');

      // Add Stripe Payment Method to hidden input
      const stripeResultInput = document.createElement('input');
      stripeResultInput.setAttribute('type', 'hidden');
      stripeResultInput.setAttribute('name', 'stripe_result');
      stripeResultInput.setAttribute('value', JSON.stringify(result));
      form.appendChild(stripeResultInput);

      // Submit form
      form.submit();
    }

    function stripeHandleError(result, element) {
      // Inform the user if there was an error
      const errorElement = document.getElementById('card-errors');
      errorElement.textContent = result.error.message;
      element.find('i').hide();
      element.removeAttr("disabled");
    }
  }
});
